import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["wrapper", "item", "destroyField"];

  addItem(event) {
    event.preventDefault();
  
    // Get the template
    const templateElement = document.querySelector("#faq-item-template");
  
    if (!templateElement) {
      console.error("Template not found!");
      return;
    }
  
    // Clone the template content
    const templateContent = templateElement.content.cloneNode(true);
  
    // Ensure we convert the cloned content to an HTML string
    const tempDiv = document.createElement("div");
    tempDiv.appendChild(templateContent);
  
    // Replace 'new' with a unique timestamp
    const timestamp = new Date().getTime();
    const html = tempDiv.innerHTML.replace(/new/g, timestamp);
  
    // Insert the new item into the wrapper
    this.wrapperTarget.insertAdjacentHTML("beforeend", html);
  }

  removeItem(event) {
    event.preventDefault();

    const item = event.target.closest("[data-nested-faq-fields-target='item']");
    const destroyField = item.querySelector("[data-nested-faq-fields-target='destroyField']");

    if (destroyField) {
      // Mark the item for destruction
      destroyField.value = "1";
      item.style.display = "none"; // Hide the item
    } else {
      // If it's a newly added item, just remove it
      item.remove();
    }
  }
}