import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["questions", "answers"];

  showAnswer(event) {
    const index = event.currentTarget.dataset.index;

    // Highlight the active question
    document.querySelectorAll(".question-item").forEach(item => {
      item.classList.remove("active");
    });
    event.currentTarget.classList.add("active");

    // Show the corresponding answer
    document.querySelectorAll(".answer-item").forEach(item => {
      if (item.dataset.index === index) {
        item.classList.remove("d-none");
        item.classList.add("d-block");
      } else {
        item.classList.add("d-none");
        item.classList.remove("d-block");
      }
    });
  }
}