import { Controller } from "stimulus";
export default class extends Controller {
  static targets = [
    "select",
    "fullImage",
    "categoryListings",
    "collectionsListingsField",
    "categoriesListingsField",
    "singleListing",
    "shared",
    "blockSettings",
    "sharedTitles",
    "smallImage",
    "bigImage",
    "textBlock",
    "videoBlock",
    "countdownBlock",
    "mapBlock",
    "contactFormBlock",
    "resourcePreviewBlock",
    "imageGalleryBlock",
    "collapsibleBlock",
    "actions"
  ];

  toggle() {
    if (this.selectTarget.value === "full image") {
      this.hideEverything();

      this.show(this.fullImageTarget);
      this.show(this.actionsTarget);
      this.show(this.sharedTitlesTarget);
      this.show(this.blockSettingsTarget);
    } else if (this.selectTarget.value === "category listings") {
      this.hideEverything();

      this.show(this.categoryListingsTarget);
      this.show(this.categoriesListingsFieldTarget);
      this.show(this.sharedTitlesTarget);
      this.show(this.blockSettingsTarget);
      this.show(this.actionsTarget);
    } else if (this.selectTarget.value === "collection listings") {
      this.hideEverything();

      this.show(this.categoryListingsTarget);
      this.show(this.collectionsListingsFieldTarget);
      this.show(this.sharedTitlesTarget);
      this.show(this.blockSettingsTarget);
      this.show(this.actionsTarget);
    } 
    else if (this.selectTarget.value === "single listing") {
      this.hideEverything();

      this.show(this.singleListingTarget);
      this.show(this.actionsTarget);
    } else if (this.selectTarget.value == 'general block') {
      this.hideEverything();

      this.show(this.actionsTarget);
      this.show(this.sharedTitlesTarget);
      this.show(this.blockSettingsTarget);
    } else if (this.selectTarget.value == 'text block') {
      this.hideEverything();

      this.show(this.textBlockTarget);
      this.show(this.sharedTitlesTarget);
      this.show(this.blockSettingsTarget);
      this.show(this.actionsTarget);
    } else if (this.selectTarget.value == 'video block') {
      this.hideEverything();

      this.show(this.videoBlockTarget);
      this.show(this.blockSettingsTarget);
      this.show(this.actionsTarget);
    } else if (this.selectTarget.value == 'countdown') {
      this.hideEverything();

      this.show(this.countdownBlockTarget);
      this.show(this.sharedTitlesTarget);
      this.show(this.blockSettingsTarget);
      this.show(this.actionsTarget);
    } else if (this.selectTarget.value == 'map'){
      this.hideEverything();

      this.show(this.mapBlockTarget);
      this.show(this.actionsTarget);
    } else if (this.selectTarget.value == 'contact form') {
      this.hideEverything()

      this.show(this.contactFormBlockTarget);
      this.show(this.actionsTarget);
    } else if (this.selectTarget.value == 'resource preview block') {
      this.hideEverything()

      this.show(this.sharedTitlesTarget);
      this.show(this.resourcePreviewBlockTarget);
      this.show(this.blockSettingsTarget);
      this.show(this.actionsTarget);
    } else if (this.selectTarget.value == 'image gallery') {
      this.hideEverything()

      this.show(this.sharedTitlesTarget);
      this.show(this.imageGalleryBlockTarget);
      this.show(this.blockSettingsTarget);
      this.show(this.actionsTarget);
    } else if (this.selectTarget.value == 'collapsible') {
      this.hideEverything()

      this.show(this.sharedTitlesTarget);
      this.show(this.collapsibleBlockTarget);
      this.show(this.blockSettingsTarget);
      this.show(this.actionsTarget);
    } else {
      this.hideEverything()
    }
  }
  showBigImage() {
    if (this.hasSmallImageTarget && this.smallImageTarget.length == 0) {
      this.bigImageTarget.classList.remove("big-device")
    }
  }
  hide(element) {
    element.classList.add('hidden')
  }
  show(element) {
    element.classList.remove('hidden')
  }
  hideEverything() {
    const excludedTargets = ["shared", "select"]; // Targets to exclude
    const hiddenClass = "hidden"; // Class to add

    this.constructor.targets
      .filter((targetName) => !excludedTargets.includes(targetName))
      .forEach((targetName) => {
        const elements = this[`${targetName}Targets`];
        elements.forEach((element) => {
          if (!element.classList.contains(hiddenClass)) {
            element.classList.add(hiddenClass);
          }
        });
      });
  }
  connect() { 
    this.toggle()
    this.showBigImage()
  }
}
