import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["slider", "item"];
  static values = { items: Number };

  connect() {
    this.currentIndex = 0;
  }

  next() {
    this.currentIndex = (this.currentIndex + 1) % this.itemTargets.length;
    this.scrollToIndex(this.currentIndex);
  }

  previous() {
    this.currentIndex =
      (this.currentIndex - 1 + this.itemTargets.length) %
      this.itemTargets.length;
    this.scrollToIndex(this.currentIndex);
  }

  scrollToIndex(index) {
    const offset = this.itemTargets[index].offsetLeft;
    this.sliderTarget.scrollTo({ left: offset, behavior: "smooth" });
  }
}